import styled from 'styled-components';
import { variables } from 'components/theme';
import {
  buttonBackgroundColor,
  checkoutLinkColor,
  tabsTextColor,
  itemTextColor,
  itemTitleColor
} from 'utils/theme';

const displayFlex = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center'
};

export const ModalHeader = styled.div`
  margin-top: 28px;
  ${variables.fonts.openSansSemiBold};
  ${displayFlex}

  @media screen and (max-width: 768px) {
    flex-direction: column;
  }

  a {
    color: ${checkoutLinkColor};
  }

  button {
    font-family: inherit;
    text-decoration: underline;
    border: none;
    background: ${buttonBackgroundColor};
    color: ${itemTitleColor};
  }
`;

export const ModalBody = styled.div`
  ${displayFlex}

  h2 {
    color: ${itemTitleColor};
  }

  button {
    border: none;
    background: ${buttonBackgroundColor};
  }

  svg {
    fill: ${itemTextColor};
  }
`;

export const ModalClose = styled.div`
  text-align: right;

  button {
    border: none;
    background: ${buttonBackgroundColor};
  }

  svg {
    fill: ${itemTextColor};
  }
`;

export const ModalContent = styled.ol`
  list-style: decimal;
  ${variables.fonts.openSansSemiBold};
  margin: 28px 0 28px 14px;

  a {
    color: ${tabsTextColor};
  }
`;

export const ModalFooter = styled.div`
  ${variables.fonts.openSansSemiBold};

  a {
    color: ${checkoutLinkColor};
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
  }
`;

export const SuccessMsg = styled.div`
  text-align: center;
  margin-bottom: 28px;

  svg {
    width: 150px;
    height: 150px;
  }

  .icon {
    fill: #dc3545;
  }

  @media screen and (max-width: 768px) {
    margin-bottom: 0px;
  }
`;
