import styled, { css } from 'styled-components';
import { variables } from 'components/theme';
import { buttonBackgroundColor, buttonBorderColor } from 'utils/theme';

export const Btn = styled.button`
  width: ${({ width }) => (width ? width : 'auto')};
  text-transform: uppercase;
  position: relative;
  display: inline-block;
  border: none;
  padding: 9px;

  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.7;
    `}

  span {
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    color: ${({ color }) =>
      color === 'yellow'
        ? 'rgba(49, 56, 62, 1)'
        : color === 'dark'
        ? variables.colors.white
        : '#0e0f13'};
    display: inherit;
    position: relative;
    z-index: 2;
    transform: translate(0.3em, -0.3em);
    transition: transform 150ms ease-out;
  }

  &::before,
  &::after {
    content: '';
    width: 100%;
    height: 100%;
    background: ${variables.colors.white};
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 0;
    box-shadow: 0 0 0 1px ${buttonBorderColor};
    border-radius: 1px;
    transition: transform 150ms ease-out;
  }

  &::after {
    background: ${({ color }) =>
      color === 'yellow'
        ? variables.colors.yellow1
        : color === 'dark'
        ? variables.colors.grey4
        : color === 'success'
        ? '#0f0'
        : color === 'danger'
        ? '#f00'
        : variables.colors.white};
    z-index: 1;
    transform: translate(0.25em, -0.25em);
    cursor: ${({ disabled }) => (disabled ? `arrow` : `pointer`)};
  }

  ::before {
    background: ${buttonBackgroundColor};
  }

  &:hover span,
  &:hover:after {
    transform: translate(0.125em, -0.125em);
    transition: transform 100ms ease-out;
  }

  &:active span,
  &:active::after {
    transform: translate(0em, 0em);
    transition: transform 50ms ease-in-out;
  }
`;

export default Btn;
