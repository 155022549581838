/**
 * Check whether the user is on a mobile device
 * @returns {boolean}
 */

export const isMobile = () =>
  /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    navigator.userAgent
  );

export const scrollToTop = () => {
  window.scrollTo(0, 0);
};

export const scrollToElement = () => {
  window.scrollTo({
    top: 200,
    left: 100,
    behavior: 'smooth'
  });
};

/**
 * Add an item to a local storage string
 * @param  {String} name      The localStorage() key
 * @param  {String} value     The localStorage() value
 */
export const addToLocalStorageString = (name, value) => {
  // Get the existing data

  let existing = localStorage.getItem(name);

  // If no existing data, use the value by itself
  // Otherwise, add the new value to it
  let data = existing ? localStorage.removeItem(name) : value;

  // Save back to localStorage
  localStorage.setItem(name, data);
};

/**
 * Add an item to a localStorage() object
 * @param {String} name  The localStorage() key
 * @param {String} key   The localStorage() value object key
 * @param {String} value The localStorage() value object value
 */
export const addToLocalStorageObject = (name, value) => {
  // Get the existing data
  let existing = localStorage.getItem(name);

  // If no existing data, create an array
  // Otherwise, convert the localStorage string to an array
  // let data = existing ? localStorage.removeItem(name) : {};

  if (existing) {
    localStorage.removeItem(name);
  }

  // Add new data to localStorage Array
  // existing[key] = value;

  // Save back to localStorage
  localStorage.setItem(name, JSON.stringify(value));
};
