import { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { BASE_URL } from 'constants.js';

export function useWindowSize() {
  const isClient = typeof window === 'object';

  function getSize() {
    return {
      width: isClient ? window.innerWidth : undefined,
      height: isClient ? window.innerHeight : undefined
    };
  }

  const [windowSize, setWindowSize] = useState(getSize);

  useEffect(() => {
    if (!isClient) {
      return false;
    }

    function handleResize() {
      setWindowSize(getSize());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // Empty array ensures that effect is only run on mount and unmount

  return windowSize;
}

export function useOnClickOutside(ref, handler) {
  useEffect(() => {
    const listener = event => {
      // Do nothing if clicking ref's element or descendent elements
      if (!ref.current || ref.current.contains(event.target)) {
        return;
      }
      handler(event);
    };
    document.addEventListener('mousedown', listener);
    document.addEventListener('touchstart', listener);
    return () => {
      document.removeEventListener('mousedown', listener);
      document.removeEventListener('touchstart', listener);
    };
  }, [ref, handler]);
}

export function useLocalStorage(key, initialValue) {
  const [storedValue, setStoredValue] = useState(() => {
    try {
      const item = window.localStorage.getItem(key);
      return item ? JSON.parse(item) : initialValue;
    } catch (error) {
      console.error(error);
      return initialValue;
    }
  });

  const setValue = value => {
    try {
      // Allow value to be a function so we have same API as useState
      const valueToStore =
        value instanceof Function ? value(storedValue) : value;
      // Save state
      setStoredValue(valueToStore);
      // Save to local storage
      window.localStorage.setItem(key, JSON.stringify(valueToStore));
    } catch (error) {
      console.error(error);
    }
  };

  return [storedValue, setValue];
}

export function useFetch(initialValue, url, options, key, paginationKey) {
  const [response, setResponse] = useLocalStorage(key, initialValue);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useLocalStorage(paginationKey, {});

  useEffect(() => {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    const isMounted = { state: true };

    async function fetchData() {
      setLoading(true);
      try {
        const res = await axios({
          url: url,
          baseURL: process.env.REACT_APP_API_URL,
          cancelToken: source.token,
          ...options
        });
        console.log('response received', res);
        if (res.data.results) {
          setResponse(res.data.results);
          setPagination({
            count: res.data.count,
            next: res.data.next,
            previous: res.data.previous
          });
        } else {
          setResponse(res.data);
        }
        setLoading(false);
      } catch (error) {
        if (!axios.isCancel(error)) {
          setError(error);
          setLoading(false);
        }
      }
    }
    if (isMounted.state) {
      fetchData();
    }

    return () => {
      isMounted.state = false;
      source.cancel('Operation canceled by the user.');
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [url]);

  return [response, { error, loading }, pagination];
}

export const useDisclose = defaultIsOpen => {
  const [isOpen, setIsOpen] = useState(Boolean(defaultIsOpen));
  const onClose = useCallback(() => setIsOpen(false), []);
  const onOpen = useCallback(() => setIsOpen(true), []);
  const onToggle = useCallback(() => setIsOpen(prevIsOpen => !prevIsOpen), []);
  return { isOpen, onOpen, onClose, onToggle };
};

export function useDebounce(value, delay) {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);
    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return debouncedValue;
}
