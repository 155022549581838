import React, { useState, useEffect, createContext, useContext } from 'react';
// import axios from 'axios';
import Cookies from 'js-cookie';
import styled, { keyframes } from 'styled-components';
import { useEffectOnce } from 'react-use';
import Button from 'components/button';
import { scrollToTop } from 'utils/browserUtil';
import { useStateValue } from 'context/store';
import { setTermsTab } from 'context/tabs/actions';

// Create context for cookie consent
const CookieContext = createContext();

export const CookieProvider = ({ children }) => {
  const [trackingEnabled, setTrackingEnabled] = useState(false);

  return (
    <CookieContext.Provider value={{ trackingEnabled, setTrackingEnabled }}>
      {children}
      <NavigationTracker />
      <CookieConsentPopup />
    </CookieContext.Provider>
  );
};

const NavigationTracker = () => {
  const { trackingEnabled } = useContext(CookieContext);

  useEffectOnce(() => {
    const trackNavigation = async path => {
      try {
        console.log('trackNavigation', {
          path,
          referrer: document.referrer,
          timestamp: new Date().toISOString()
          //   screenResolution: `${window.screen.width}x${window.screen.height}`
        });

        // await axios.post('/api/track-navigation', {
        //   path,
        //   referrer: document.referrer,
        //   timestamp: new Date().toISOString(),
        //   screenResolution: `${window.screen.width}x${window.screen.height}`
        // });
      } catch (error) {
        console.error('Navigation tracking error:', error);
      }
    };

    const handleClick = e => {
      if (!trackingEnabled) return;

      const link = e.target.closest('a');
      if (link) {
        e.preventDefault();
        const href = link.href;
        const linkText = link.textContent.trim();
        const isInternal = href.startsWith(window.location.origin);

        trackNavigation({
          href,
          linkText,
          isInternal,
          target: link.target || '_self'
        });

        setTimeout(() => {
          window.location.href = href;
        }, 150);
      }
    };

    document.addEventListener('click', handleClick);
    return () => document.removeEventListener('click', handleClick);
  });

  return null;
};

const CookieConsentPopup = () => {
  const [isVisible, setIsVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const { setTrackingEnabled } = useContext(CookieContext);

  useEffect(() => {
    if (!Cookies.get('cookie_consent')) {
      setIsVisible(true);
    }
  }, []);

  const savePreference = async consent => {
    setLoading(true);
    try {
      console.log('savePreference', {
        consent,
        timestamp: new Date().toISOString(),
        consentVersion: '1.0'
      });

      //   await axios.post('/api/save-cookie-choice', {
      //     consent,
      //     timestamp: new Date().toISOString(),
      //     consentVersion: '1.0'
      //   });

      Cookies.set('cookie_consent', consent ? 'accepted' : 'declined', {
        expires: consent ? 365 : 30,
        sameSite: 'Lax'
      });

      setTrackingEnabled(consent);
      setIsVisible(false);

      if (consent) {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({ event: 'cookie_consent_accepted' });
      }
    } catch (error) {
      console.error('Error saving cookie preference:', error);
    } finally {
      setLoading(false);
    }
  };

  if (!isVisible) return null;

  return (
    <PopupContainer>
      <Content>
        <Message>
          We use cookies to enhance your event experience.
          <PrivacyLink href="/terms" target="_blank">
            Learn more in our Privacy Policy
          </PrivacyLink>
        </Message>
        <ButtonsContainer>
          <Button
            width="200px"
            style={{
              marginLeft: 2,
              marginRight: 28
            }}
            color="white"
            onClick={() => savePreference(false)}
            aria-label="Decline cookies"
          >
            {loading ? 'Saving...' : 'Decline All'}
          </Button>
          <Button
            width="200px"
            style={{
              marginLeft: 2,
              marginRight: 28
            }}
            color="yellow"
            onClick={() => savePreference(true)}
            aria-label="Accept cookies"
          >
            {' '}
            {loading ? 'Saving...' : 'Accept All'}
          </Button>
        </ButtonsContainer>
      </Content>
    </PopupContainer>
  );
};

// Animations
const slideUp = keyframes`
  from { transform: translateY(100%); }
  to { transform: translateY(0); }
`;

// Styled components
const PopupContainer = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(40, 40, 40, 0.98);
  color: #ffffff;
  padding: 1.5rem;
  z-index: 1000;
  animation: ${slideUp} 0.5s cubic-bezier(0.23, 1, 0.32, 1);
  box-shadow: 0 -4px 20px rgba(0, 0, 0, 0.15);
  backdrop-filter: blur(8px);
`;

const Content = styled.div`
  max-width: 1440px;
  margin: 24px auto;
  display: flex;

  align-items: center;
  justify-content: space-between;
  gap: 32px;

  @media (max-width: 768px) {
    flex-direction: column;
    gap: 1rem;
  }
`;

const Message = styled.p`
  margin: 0;
  font-size: 0.95rem;
  line-height: 1.6;
  color: #e0e0e0;

  @media (max-width: 768px) {
    text-align: center;
  }
`;

const PrivacyLink = styled.a`
  color: #7ec8ff;
  text-decoration: none;
  margin-left: 0.5rem;
  font-weight: 500;
  transition: color 0.2s ease;

  &:hover {
    color: #4ab0ff;
    text-decoration: underline;
  }
`;

const ButtonsContainer = styled.div`
  display: flex;
  gap: 1rem;
  justify-content: flex-end;
  flex-wrap: wrap;

  @media (max-width: 768px) {
    justify-content: center;
    width: 100%;
    button {
      margin: 0.5rem 0;
      width: 100%;
    }
  }
`;

const BaseButton = styled.button`
  padding: 0.8rem 1.8rem;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  font-weight: 600;
  transition: all 0.25s ease;
  font-size: 0.9rem;
  min-width: 140px;

  &:disabled {
    opacity: 0.7;
    cursor: not-allowed;
  }
`;

export default CookieProvider;
