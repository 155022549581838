import React from 'react';
import Modal from 'react-modal';

import { ReactComponent as Close } from 'assets/icons/close.svg';
import {
  ModalContent,
  ModalBody,
  ModalClose
} from 'containers/CheckoutModal/elements';

import { useWindowSize } from 'utils/hooks';

const DesktopStyles = {
  overlay: {
    background: 'rgba(49, 56, 62, 0.7)',
    zIndex: 100
  },
  content: {
    maxWidth: 587,
    borderRadius: 8,
    top: '5%',
    bottom: 'initial',
    margin: 'auto',
    padding: 35
  }
};

const MobileStyles = {
  overlay: {
    background: 'rgba(49, 56, 62, 0.7)',
    zIndex: 100
  },
  content: {
    width: '100%',
    position: 'static'
  }
};

function CalendarModal(props) {
  const { isOpen, children, onRequestClose } = props;

  const { width } = useWindowSize();

  if (width === undefined || width === null) {
    // Wait for the window size
    return <div>{null}</div>;
  }

  function handleWidth() {
    if (width <= 768) {
      return MobileStyles;
    } else {
      return DesktopStyles;
    }
  }

  return (
    <Modal
      style={handleWidth()}
      isOpen={isOpen}
      onRequestClose={onRequestClose}
    >
      <ModalClose>
        <button onClick={onRequestClose}>
          <Close />
        </button>
      </ModalClose>
      <ModalBody>
        <h2>Select one of the following:</h2>
      </ModalBody>
      <ModalBody>
        <ModalContent style={{ marginTop: 0 }}>
          {children.map((item, idx) => (
            <li key={idx}>{item}</li>
          ))}
        </ModalContent>
      </ModalBody>
    </Modal>
  );
}

export default CalendarModal;
