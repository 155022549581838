/**
 * Button
 */

import React from 'react';
import AddToCalendarHOC from 'react-add-to-calendar-hoc';

import { ReactComponent as Calendar } from 'assets/icons/calendar.svg';

import CalendarModal from 'components/calendarModal';

import Btn from './elements';

function Button(props) {
  const { name, disabled, children, ...rest } = props;
  return (
    <Btn name={name} {...rest} disabled={disabled}>
      <span>{children}</span>
    </Btn>
  );
}

export const CalendarButton = AddToCalendarHOC(
  ({ children, onClick }) => (
    <Button width="100%" onClick={onClick}>
      {children} <Calendar style={{ marginLeft: 7, verticalAlign: 'middle' }} />
    </Button>
  ),
  CalendarModal
);

export default Button;
